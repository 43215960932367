<template>
  <div class="mainwidth">
    <div class="digitization-content">
      <h2>数字化水平测试</h2>
      <a-form
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="vertical"
        ref="formRef"
      >
        <a-form-item label="1.企业的数字化设备覆盖范围">
          <a-select
            v-model:value="questionOne"
            placeholder="请选择企业的数字化设备覆盖范围"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="2.企业的数字化设备联网率">
          <a-select
            v-model:value="questionTwo"
            placeholder="请选择企业的数字化设备联网率"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list1"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="3.企业的关键工序数控化率">
          <a-select
            v-model:value="questionThree"
            placeholder="请选择企业的关键工序数控化率"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list2"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="4.企业通过部署工业互联网公有云/私有云/混合云平台等形式，实现业务的数字化管理情况"
        >
          <a-select
            v-model:value="questionFour"
            placeholder="请选择企业通过部署工业互联网公有云/私有云/混合云平台等形式，实现业务的数字化管理情况"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list3"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="5.企业实现数据自动/半自动获取并展示的业务">
          <a-checkbox-group v-model:value="questionFive">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list4"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="6.企业实现各类数据汇聚及应用的情况">
          <a-checkbox-group v-model:value="questionSix">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list5"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="7.企业在保障网络安全方面采取的举措情况">
          <a-select
            v-model:value="questionSeven"
            placeholder="请选择企业在保障网络安全方面采取的举措情况"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list6"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="8.研发设计环节，企业开展数字化研发设计的情况">
          <div>
            （注 ：50%以上关键业务研发设计项目实现下列数字化场景即可勾选该项
          </div>
          <a-checkbox-group v-model:value="questionEight">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list7"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="9.生产计划环节，企业实现生产计划排产排程的情况">
          <a-select
            v-model:value="questionNine"
            placeholder="请选择生产计划环节，企业实现生产计划排产排程的情况"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list8"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="10.生产监控环节，企业利用信息系统实现生产过程监控的情况"
        >
          <a-checkbox-group v-model:value="questionTen">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list9"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label="11.生产作业环节，企业实现智能制造典型场景的覆盖范围"
        >
          <a-checkbox-group v-model:value="questionEleven">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list10"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label="12.质量控制环节，企业运用数字化手段提高质量控制能力的重点场景覆盖范围"
        >
          <div>（注1：某一种或几种关键产品或物料实现</div>
          <a-checkbox-group v-model:value="questionTwelve">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list11"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label="13.仓储物流环节：企业实现仓储物流数字化场景的覆盖范围"
        >
          <div>
            （注1：50%以上关键原料、半成品、成品等实现下列数字化场景即可勾选该项
          </div>
          <div>
            （注2：通过第三方、第四方物流实现的仓储物流数字化，如覆盖下述场景也可勾选）
          </div>
          <a-checkbox-group v-model:value="questionThirteen">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list12"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label="14.采购供应环节：企业实现采购管理数字化场景的覆盖范围"
        >
          <div>
            （注：50%以上关键业务采购活动实现下列数字化场景即可勾选该项）
          </div>
          <a-checkbox-group v-model:value="questionFourteen">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list13"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label="15.营销管理环节，企业实现营销管理数字化场景的覆盖范围"
        >
          <div>
            （注：50%以上关键业务市场营销实现下列数字化场景即可勾选该项））
          </div>
          <a-checkbox-group v-model:value="questionFifteen">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list14"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label="16.产品服务环节，企业实现产品服务数字化场景的覆盖范围"
        >
          <div>
            （1）利用信息系统实现售后服务数字化管理的情况（50%以上关键产品售后服务实现下列数字化场景即可勾选该项）
          </div>
          <a-checkbox-group v-model:value="questionSixteenOne">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list151"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
          <div>
            （2）利用数字化技术实现售后服务与相关业务的协同情况（50%以上关键产品售后服务实现下列数字化场景即可勾选该项）
          </div>
          <a-checkbox-group v-model:value="questionSixteenTwo">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list152"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
          <div>
            （3）新一代信息技术在新型智能产品中应用场景的覆盖范围（某一种或几种关键产品实现下列数字化场景即可勾选该项）
          </div>
          <a-checkbox-group v-model:value="questionSixteenThree">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list153"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item
          label="17.业务协同方面，企业使用数字化技术实现企业间业务协同数字化场景的覆盖范围"
        >
          <div>
            （注：50%以上关键业务客户、上游供应企业或合作伙伴使用数字化技术实现下列数字化场景即可勾选该项）
          </div>
          <a-checkbox-group
            v-model:value="questionSeventeen"
            class="vertical-checkbox-group"
          >
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list16"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="18.企业数字化转型意识与执行水平情况">
          <a-checkbox-group v-model:value="questionEighteen">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list17"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="19.企业在设置数字化组织与管理制度等方面采取的措施">
          <a-checkbox-group v-model:value="questionNineteen">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list18"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="20.企业在数字化方面培训覆盖的人员范围">
          <a-checkbox-group v-model:value="questionTwenty">
            <a-checkbox
              :value="item.value"
              v-for="(item, index) in list19"
              :key="index"
              >{{ item.label }}</a-checkbox
            >
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="21.企业上年度数字化投入占营业收入的比重">
          <a-select
            v-model:value="questionTwentyOne"
            placeholder="请选择企业上年度数字化投入占营业收入的比重"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list20"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="22.企业上年度产品合格率">
          <a-select
            v-model:value="questionTwentyTwo"
            placeholder="请选择企业上年度产品合格率"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list21"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="23.企业上年度人均营业收">
          <a-select
            v-model:value="questionTwentyThree"
            placeholder="请选择企业上年度人均营业收"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list22"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="24.企业上年度每百元营业收入中的成本">
          <a-select
            v-model:value="questionTwentyFour"
            placeholder="请选择企业上年度每百元营业收入中的成本"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in list23"
              :key="index"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </div>
    <div style="margin-top: 20px;margin-left: 50%">
      <a-button type="primary" @click="submit" style="width: 160px;height: 40px"
        >提交</a-button
      >
    </div>
  </div>
</template>

<script>
import { getQuestionnaire } from "../api/digitization/index";
import { mapGetters } from "vuex";
export default {
  name: "Evaluating",
  computed: {
    ...mapGetters(["orgId", "isAuth", "loginType", "orgName"]),
  },
  data() {
    return {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
      questionOne: "",
      questionTwo: "",
      questionThree: "",
      questionFour: "",
      questionSeven: "",
      questionNine: "",
      questionTwentyOne: "",
      questionTwentyTwo: "",
      questionTwentyThree: "",
      questionTwentyFour: "",
      // 多选
      questionFive: [],
      questionSix: [],
      questionEight: [],
      questionTen: [],
      questionEleven: [],
      questionTwelve: [],
      questionThirteen: [],
      questionFourteen: [],
      questionFifteen: [],
      questionSixteenOne: [],
      questionSixteenTwo: [],
      questionSixteenThree: [],
      questionSeventeen: [],
      questionEighteen: [],
      questionNineteen: [],
      questionTwenty: [],

      // rules: {
      //   questionOne: [
      //     {
      //       required: true,
      //       message: "请选择企业的数字化设备覆盖范围",
      //       trigger: "change",
      //     },
      //   ],
      //   questionTwo: [
      //     {
      //       required: true,
      //       message: "请选择企业的数字化设备联网率",
      //       trigger: "change",
      //     },
      //   ],
      //   questionThree: [
      //     {
      //       required: true,
      //       message: "请选择企业的关键工序数控化率",
      //       trigger: "change",
      //     },
      //   ],
      //   questionFour: [
      //     {
      //       required: true,
      //       message:
      //         "请选择企业通过部署工业互联网公有云/私有云/混合云平台等形式，实现业务的数字化管理情况",
      //       trigger: "change",
      //     },
      //   ],
      //   questionSeven: "",
      //   questionNine: "",
      //   questionTwentyOne: "",
      //   questionTwentyTwo: "",
      //   questionTwentyThree: "",
      //   questionTwentyFour: "",
      //   // 多选
      //   questionFive: [],
      //   questionSix: [],
      //   questionEight: [],
      //   questionTen: [],
      //   questionEleven: [],
      //   questionTwelve: [],
      //   questionThirteen: [],
      //   questionFourteen: [],
      //   questionFifteen: [],
      //   questionSixteenOne: [],
      //   questionSixteenTwo: [],
      //   questionSixteenThree: [],
      //   questionSeventeen: [],
      //   questionEighteen: [],
      //   questionNineteen: [],
      //   questionTwenty: [],
      // },
      list: [
        {
          value: "A",
          label: "单个业务环节",
        },
        {
          value: "B",
          label: "关键业务环节",
        },
        {
          value: "C",
          label: "绝大部分业务环节",
        },
        {
          value: "D",
          label: "全覆盖",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list1: [
        {
          value: "A",
          label: "0-10%",
        },
        {
          value: "B",
          label: "10%-20%",
        },
        {
          value: "C",
          label: "20%-30%",
        },
        {
          value: "D",
          label: "30%-40%",
        },
        {
          value: "E",
          label: "40%以上",
        },
      ],
      list2: [
        {
          value: "A",
          label: "0-30%",
        },
        {
          value: "B",
          label: "30%-45%",
        },
        {
          value: "C",
          label: "45%-60%",
        },
        {
          value: "D",
          label: "60%以上",
        },
      ],
      list3: [
        {
          value: "A",
          label: "单个业务环节",
        },
        {
          value: "B",
          label: "关键业务环节",
        },
        {
          value: "C",
          label: "绝大部分业务环节",
        },
        {
          value: "D",
          label: "全覆盖",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list4: [
        {
          value: "A",
          label: "研发设计",
        },
        {
          value: "B",
          label: "生产管控",
        },
        {
          value: "C",
          label: "质量控制",
        },
        {
          value: "D",
          label: "仓储配送（厂内）",
        },
        {
          value: "E",
          label: "设备管理",
        },
        {
          value: "F",
          label: "采购",
        },
        {
          value: "G",
          label: "销售",
        },
        {
          value: "H",
          label: "物流（厂外）",
        },
        {
          value: "I",
          label: "财务",
        },
        {
          value: "J",
          label: "人力",
        },
        {
          value: "K",
          label: "以上均无",
        },
      ],
      list5: [
        {
          value: "A",
          label: "建立了统一的数据编码、数据交换格式和规则等",
        },
        {
          value: "B",
          label: "实现了数据及分析结果的跨部门共享",
        },
        {
          value: "C",
          label: "构建了数据算法模型，支撑业务人员进行数据分析",
        },
        {
          value: "D",
          label: "构建了可视化数据分析工具",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list6: [
        {
          value: "A",
          label: "使用了工业级网络安全产品及服务，尚未建立网络安全保障制度",
        },
        {
          value: "B",
          label: "建立了网络安全保障制度，尚未开展网络安全等级自评估",
        },
        {
          value: "C",
          label: "开展了网络安全等级自评估，尚未通过第三方机构的验收认定",
        },
        {
          value: "D",
          label: "网络安全等级评估通过了第三方机构的验收认定",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list7: [
        {
          value: "A",
          label: "应用二维、三维计算机设计软件辅助开展设计工作",
        },
        {
          value: "B",
          label: "使用PDM或PLM等软件实现文档、数据、流程等的共享和统一管理",
        },
        {
          value: "C",
          label: "建设和应用产品设计标准库、组件库或知识库",
        },
        {
          value: "D",
          label:
            "将产品设计信息集成于产品的数字化模型中，实现产品设计数据的唯一性",
        },
        {
          value: "E",
          label: "实现产品设计和工艺设计间的信息交互和并行协同",
        },
        {
          value: "F",
          label: "以上均无",
        },
      ],
      list8: [
        {
          value: "A",
          label: "通过信息系统实现具有约束条件的主生产计划生产和物料需求计算",
        },
        {
          value: "B",
          label: "通过信息系统开展车间计划排产",
        },
        {
          value: "C",
          label: "部分车间生产计划实现自动排产",
        },
        {
          value: "D",
          label: "全部车间生产计划实现自动排产",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list9: [
        {
          value: "A",
          label: "设备：能够在一种或多种单个设备层面实现生产过程监控",
        },
        {
          value: "B",
          label: "工序：能够在一道或多道工序层面实现生产过程监控",
        },
        {
          value: "C",
          label: "生产线：能够在一条或多条生产线层面实现生产过程监控",
        },
        {
          value: "D",
          label: "车间：能够在一个或多个车间层面实现生产过程监控",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list10: [
        {
          value: "A",
          label:
            "自动巡检：应用智能巡检装备或设备管理系统，集成数字化技术，实现对设备的高效巡检或异常报警等（50%以上关键业务设备实现下列数字化场景即可勾选该项）",
        },
        {
          value: "B",
          label:
            "生产过程可视化：依托各类生产、系统集成，实现生产成本、交期或订单执行进度的可视化（50%以上关键业务生产成本、交期或订单执行进度实现即可勾选",
        },
        {
          value: "C",
          label:
            "精益生产管理：应用数字化工具和方法，开展数据驱动的人、机、料等精确管控，减少生产浪费（50%以上关键业务人、机、料等实现即可勾选）",
        },
        {
          value: "D",
          label:
            "人机协同作业：集成机器人、高端机床或人机交互设备等智能装备，应用AR/VR、机器视觉等技术，实现生产的高效组织和作业协同（50%以上关键产线实现即可勾选）",
        },
        {
          value: "E",
          label:
            "基于数字孪生的制造：构建装备、产线、车间、工厂等一种或几种不同层级的数字孪生系统，实现物理世界和虚拟空间的实时映射，推动感知、分析、预测和控制能力的全面提升（某一或几种/个关键装备、产线、车间或工厂实现即可勾选）",
        },
        {
          value: "F",
          label: "以上均无",
        },
      ],
      list11: [
        {
          value: "A",
          label:
            "数字化检测：应用数字化设备和技术，实现关键环节的在线检测、分析、结果判定",
        },
        {
          value: "B",
          label:
            "质量精准追溯：应用数字化技术，采集产品原料、生产过程、客户使用的质量信息等信息，实现产品质量全过程精准追溯",
        },
        {
          value: "C",
          label:
            "产品质量优化：应用数字化技术，实现产品质量影响因素识别、缺陷分析预测或质量优化提升",
        },
        {
          value: "D",
          label:
            "质量控制协同：利用数字化手段实现质量控制与相关业务的协同，包括质量与规范同步、检测数据与设备信息同步、供应商质量信息同步、客户质量信息同步等",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list12: [
        {
          value: "A",
          label: "物料条码管理：统一条码管理标识货物",
        },
        {
          value: "B",
          label:
            "智能仓储：应用数字化技术，依据实际生产作业计划，实现物料自动入库（进厂）、盘库或出库（出厂）",
        },
        {
          value: "C",
          label: "精准配送：应用数字化技术，实现动态调度、自动配送或路径优化",
        },
        {
          value: "D",
          label:
            "物料实时跟踪：应用制造执行系统（MES）或仓储管理系统（WMS），采用数字化技术，实现原材料、在制品或产成品流转的全程跟踪",
        },
        {
          value: "E",
          label:
            "物流监测与优化：依托运输管理系统（TMS），应用数字化技术，实现运输配送全程跟踪或异常预警，装载能力优化或配送路径优化",
        },
        {
          value: "F",
          label: "以上均无",
        },
      ],
      list13: [
        {
          value: "A",
          label:
            "采购管理信息化：通过信息系统实现采购计划管理、采购过程管理和供应商管理",
        },
        {
          value: "B",
          label:
            "采购策略优化：建设供应链管理系统（SCM），集成数字化技术，实现供应商综合评价、采购需求精准决策或采购方案动态优化",
        },
        {
          value: "C",
          label:
            "供应链可视化：搭建供应链管理系统（SCM），融合数字化技术，实现供应链可视化监控",
        },
        {
          value: "D",
          label:
            "供应链风险预警与弹性管控：建立供应链管理系统（SCM），集成数字化技术，开展供应链风险隐患识别、定位、预警或高效处置",
        },
        {
          value: "E",
          label:
            "采购协同：利用数字化技术实现采购供应与相关业务的协同，包括业务配合同步、质量与规范同步、结算对账同步、库存与计划同步等",
        },
        {
          value: "F",
          label: "以上均无",
        },
      ],
      list14: [
        {
          value: "A",
          label:
            "销售计划动态优化：依托客户关系管理系统（CRM），应用数字化技术，实现挖掘分析客户信息、构建用户画像、构建需求预测模型或制定精准销售计划",
        },
        {
          value: "B",
          label:
            "市场快速分析预测：应用数字化技术，实现对市场未来供求趋势、影响因素或其变化规律的精准分析、判断或预测",
        },
        {
          value: "C",
          label:
            "销售驱动业务优化：应用数字化技术，根据客户需求变化，动态调整设计采购、生产或物流等方案",
        },
        {
          value: "D",
          label: "以上均无",
        },
      ],
      list151: [
        {
          value: "A",
          label: "退换货质量管理",
        },
        {
          value: "B",
          label: "客户体验调查",
        },
        {
          value: "C",
          label: "客户满意度调查",
        },
        {
          value: "D",
          label: "以上均无",
        },
      ],
      list152: [
        {
          value: "A",
          label: "售后配件与库存协同",
        },
        {
          value: "B",
          label: "失效产品追溯与质量根因分析",
        },
        {
          value: "C",
          label: "失效原因与设计优化协同",
        },
        {
          value: "D",
          label: "以上均无",
        },
      ],
      list153: [
        {
          value: "A",
          label:
            "数据增值服务：分析产品的运行工况等数据，应用数字化技术，提供设备估值、融资租赁、资产处置等新业务",
        },
        {
          value: "B",
          label:
            "主动客户服务：依托客户关系管理系统（CRM），集成数字化技术，实现精细化管理或主动式客户服务",
        },
        {
          value: "C",
          label:
            "用户直连制造：通过用户和企业的深度交互，提供满足个性化需求的产品定制设计、柔性化生产或个性化服务",
        },
        {
          value: "D",
          label:
            "大批量定制：通过生产柔性化、敏捷化或产品模块化，根据客户的个性化需求，以大批量生产方式提供定制化的产品和服务",
        },
        {
          value: "E",
          label:
            "产品的远程运维：依托产品远程运维管理平台，实现基于运行数据的产品远程监控、预测性维护或产品设计的持续改进",
        },
        {
          value: "F",
          label: "以上均无",
        },
      ],
      list16: [
        {
          value: "A",
          label: "实现研发设计协同",
        },
        {
          value: "B",
          label: "实现生产制造协同",
        },
        {
          value: "C",
          label: "实现订货业务协同",
        },
        {
          value: "D",
          label: "实现物流仓储协同",
        },
        {
          value: "E",
          label: "实现财务结算协同",
        },
        {
          value: "F",
          label: "以上均无",
        },
      ],
      list17: [
        {
          value: "A",
          label: "已经对数字化转型有了明确的目标（至少半年为期）",
        },
        {
          value: "B",
          label: "已制定了数字化转型规划及具体的实施计划",
        },
        {
          value: "C",
          label: "已基于战略规划开展业务模式和管理决策方式的变革实践",
        },
        {
          value: "D",
          label: "以上均无",
        },
      ],
      list18: [
        {
          value: "A",
          label: "设置专门的数字化人员岗位或部门 ",
        },
        {
          value: "B",
          label: "为数字化人才设立专门的绩效薪酬体系 ",
        },
        {
          value: "C",
          label: "对数字化收支单独建账核算",
        },
        {
          value: "D",
          label: "建立数字化信息系统管理相关制度规范 ",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list19: [
        {
          value: "A",
          label: "信息化部门员工  ",
        },
        {
          value: "B",
          label: "业务部门员工 ",
        },
        {
          value: "C",
          label: "企业主要决策人员",
        },
        {
          value: "D",
          label: "其他员工 ",
        },
        {
          value: "E",
          label: "以上均无",
        },
      ],
      list20: [
        {
          value: "A",
          label: "小于1%  ",
        },
        {
          value: "B",
          label: "1%-2% ",
        },
        {
          value: "C",
          label: "2%-3%",
        },
        {
          value: "D",
          label: "3%-5% ",
        },
        {
          value: "E",
          label: "大于5%",
        },
      ],
      list21: [
        {
          value: "A",
          label: "明显低于行业平均水平",
        },
        {
          value: "B",
          label: "略低于行业平均水平",
        },
        {
          value: "C",
          label: "与行业平均水平相当",
        },
        {
          value: "D",
          label: "略高于行业平均水平",
        },
        {
          value: "E",
          label: "明显高于行业平均水平",
        },
      ],
      list22: [
        {
          value: "A",
          label: "明显低于行业平均水平",
        },
        {
          value: "B",
          label: "略低于行业平均水平",
        },
        {
          value: "C",
          label: "与行业平均水平相当",
        },
        {
          value: "D",
          label: "略高于行业平均水平",
        },
        {
          value: "E",
          label: "明显高于行业平均水平",
        },
      ],
      list23: [
        {
          value: "A",
          label: "明显低于行业平均水平",
        },
        {
          value: "B",
          label: "略低于行业平均水平",
        },
        {
          value: "C",
          label: "与行业平均水平相当",
        },
        {
          value: "D",
          label: "略高于行业平均水平",
        },
        {
          value: "E",
          label: "明显高于行业平均水平",
        },
      ],
      timer: null,
    };
  },
  methods: {
    submit() {
      if (this.questionOne == "undefined" || this.questionOne == "") {
        this.$message.info("请选择企业的数字化设备覆盖范围");
        return false;
      } else if (this.questionTwo == "undefined" || this.questionTwo == "") {
        this.$message.info("请选择企业的数字化设备联网率");
        return false;
      } else if (
        this.questionThree == "undefined" ||
        this.questionThree == ""
      ) {
        this.$message.info("请选择企业的关键工序数控化率");
        return false;
      } else if (this.questionFour == "undefined" || this.questionFour == "") {
        this.$message.info(
          "请选择企业通过部署工业互联网公有云/私有云/混合云平台等形式，实现业务的数字化管理情况"
        );
        return false;
      } else if (this.questionFive == "undefined" || this.questionFive == "") {
        //1
        this.$message.info("请选择企业实现数据自动/半自动获取并展示的业务");
        return false;
      } else if (this.questionSix == "undefined" || this.questionSix == "") {
        this.$message.info("请选择企业实现各类数据汇聚及应用的情况");
        return false;
      } else if (
        this.questionSeven == "undefined" ||
        this.questionSeven == ""
      ) {
        this.$message.info("请选择企业在保障网络安全方面采取的举措情况");
        return false;
      } else if (
        this.questionEight == "undefined" ||
        this.questionEight == ""
      ) {
        this.$message.info("请选择研发设计环节，企业开展数字化研发设计的情况");
        return false;
      } else if (this.questionNine == "undefined" || this.questionNine == "") {
        this.$message.info(
          "请选择生产计划环节，企业实现生产计划排产排程的情况"
        );
        return false;
      } else if (this.questionTen == "undefined" || this.questionTen == "") {
        this.$message.info(
          "请选择生产监控环节，企业利用信息系统实现生产过程监控的情况"
        );
        return false;
      } else if (
        this.questionEleven == "undefined" ||
        this.questionEleven == ""
      ) {
        this.$message.info(
          "请选择生产作业环节，企业实现智能制造典型场景的覆盖范围"
        );
        return false;
      } else if (
        this.questionTwelve == "undefined" ||
        this.questionTwelve == ""
      ) {
        this.$message.info(
          "请选择质量控制环节，企业运用数字化手段提高质量控制能力的重点场景覆盖范围"
        );
        return false;
      } else if (
        this.questionThirteen == "undefined" ||
        this.questionThirteen == ""
      ) {
        this.$message.info(
          "请选择仓储物流环节：企业实现仓储物流数字化场景的覆盖范围"
        );
        return false;
      } else if (
        this.questionFourteen == "undefined" ||
        this.questionFourteen == ""
      ) {
        this.$message.info(
          "请选择采购供应环节：企业实现采购管理数字化场景的覆盖范围"
        );
        return false;
      } else if (
        this.questionFifteen == "undefined" ||
        this.questionFifteen == ""
      ) {
        this.$message.info(
          "请选择营销管理环节，企业实现营销管理数字化场景的覆盖范围"
        );
        return false;
      } else if (
        this.questionSixteenOne == "undefined" ||
        this.questionSixteenOne == ""
      ) {
        this.$message.info("请选择利用信息系统实现售后服务数字化管理的情况");
        return false;
      } else if (
        this.questionSixteenTwo == "undefined" ||
        this.questionSixteenTwo == ""
      ) {
        this.$message.info(
          "请选择利用数字化技术实现售后服务与相关业务的协同情况"
        );
        return false;
      } else if (
        this.questionSixteenThree == "undefined" ||
        this.questionSixteenThree == ""
      ) {
        this.$message.info(
          "请选择新一代信息技术在新型智能产品中应用场景的覆盖范围"
        );
        return false;
      } else if (
        this.questionSeventeen == "undefined" ||
        this.questionSeventeen == ""
      ) {
        this.$message.info(
          "请选择业务协同方面，企业使用数字化技术实现企业间业务协同数字化场景的覆盖范围"
        );
        return false;
      } else if (
        this.questionEighteen == "undefined" ||
        this.questionEighteen == ""
      ) {
        this.$message.info("请选择企业数字化转型意识与执行水平情况");
        return false;
      } else if (
        this.questionNineteen == "undefined" ||
        this.questionNineteen == ""
      ) {
        this.$message.info(
          "请选择企业在设置数字化组织与管理制度等方面采取的措施"
        );
        return false;
      } else if (
        this.questionTwenty == "undefined" ||
        this.questionTwenty == ""
      ) {
        this.$message.info("请选择企业在数字化方面培训覆盖的人员范围");
        return false;
      } else if (
        this.questionTwentyOne == "undefined" ||
        this.questionTwentyOne == ""
      ) {
        this.$message.info("请选择企业上年度数字化投入占营业收入的比重");
        return false;
      } else if (
        this.questionTwentyTwo == "undefined" ||
        this.questionTwentyTwo == ""
      ) {
        this.$message.info("请选择企业上年度产品合格率");
        return false;
      } else if (
        this.questionTwentyThree == "undefined" ||
        this.questionTwentyThree == ""
      ) {
        this.$message.info("请选择企业上年度人均营业收");
        return false;
      } else if (
        this.questionTwentyFour == "undefined" ||
        this.questionTwentyFour == ""
      ) {
        this.$message.info("请选择企业上年度每百元营业收入中的成本");
        return false;
      }
      let params = {
        enterpriseId: this.orgId,
        questionOne: this.questionOne,
        questionTwo: this.questionTwo,
        questionThree: this.questionThree,
        questionFour: this.questionFour,
        questionSeven: this.questionSeven,
        questionNine: this.questionNine,
        questionTwentyOne: this.questionTwentyOne,
        questionTwentyTwo: this.questionTwentyTwo,
        questionTwentyThree: this.questionTwentyThree,
        questionTwentyFour: this.questionTwentyFour,
        // 多选
        questionFive: this.questionFive.join(),
        questionSix: this.questionSix.join(),
        questionEight: this.questionEight.join(),
        questionTen: this.questionTen.join(),
        questionEleven: this.questionEleven.join(),
        questionTwelve: this.questionTwelve.join(),
        questionThirteen: this.questionThirteen.join(),
        questionFourteen: this.questionFourteen.join(),
        questionFifteen: this.questionFifteen.join(),
        questionSixteenOne: this.questionSixteenOne.join(),
        questionSixteenTwo: this.questionSixteenTwo.join(),
        questionSixteenThree: this.questionSixteenThree.join(),
        questionSeventeen: this.questionSeventeen.join(),
        questionEighteen: this.questionEighteen.join(),
        questionNineteen: this.questionNineteen.join(),
        questionTwenty: this.questionTwenty.join(),
      };
      this.$store.dispatch("questionnaireAdd", params).then((res) => {
        if (res.code == 200) {
          this.$message.success("提交成功");
          // localStorage.setItem("surveyId", res.data.id);
          this.timer = setInterval(() => {
            this.$router.go(-1);
            clearInterval(this.timer);
          }, 1000);
        }
      });
    },
    // 获取详情
    getInfo() {
      // let id = localStorage.getItem("surveyId");
      getQuestionnaire(this.orgId).then((res) => {
        this.questionOne = res.data.questionOne;
        this.questionTwo = res.data.questionTwo;
        this.questionThree = res.data.questionThree;
        this.questionFour = res.data.questionFour;
        this.questionSeven = res.data.questionSeven;
        this.questionNine = res.data.questionNine;
        this.questionTwentyOne = res.data.questionTwentyOne;
        this.questionTwentyTwo = res.data.questionTwentyTwo;
        this.questionTwentyThree = res.data.questionTwentyThree;
        this.questionTwentyFour = res.data.questionTwentyFour;
        // 多选
        this.questionFive = res.data.questionFive.split(",");
        this.questionSix = res.data.questionSix.split(",");
        this.questionEight = res.data.questionEight.split(",");
        this.questionTen = res.data.questionTen.split(",");
        this.questionEleven = res.data.questionEleven.split(",");
        this.questionTwelve = res.data.questionTwelve.split(",");
        this.questionThirteen = res.data.questionThirteen.split(",");
        this.questionFourteen = res.data.questionFourteen.split(",");
        this.questionFifteen = res.data.questionFifteen.split(",");
        this.questionSixteenOne = res.data.questionSixteenOne.split(",");
        this.questionSixteenTwo = res.data.questionSixteenTwo.split(",");
        this.questionSixteenThree = res.data.questionSixteenThree.split(",");
        this.questionSeventeen = res.data.questionSeventeen.split(",");
        this.questionEighteen = res.data.questionEighteen.split(",");
        this.questionNineteen = res.data.questionNineteen.split(",");
        this.questionTwenty = res.data.questionTwenty.split(",");
      });
    },
  },
  mounted() {
    if (this.orgId) {
      this.getInfo();
    }
  },

  beforeDestroy() {
    // 在组件销毁前清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="less" scoped>
.mainwidth {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}
.digitization-content {
  box-shadow: 0px 2px 10px #929292;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 40px;

  h2 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px; /* 根据需要调整间距 */
}
.ant-checkbox-wrapper {
  margin-left: 8px !important;
}
</style>
